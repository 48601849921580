<template>
  <div class="pay van-tab__panel">
    <div v-if="!loading">
      <div class="lot-head shadow lottery-context txt-center rel">
        <!-- <h1 class="border-r5">{{ lotteryInfo.lottery_name }}</h1> -->

        <div class="money default shadow border-r5">
          <van-row class="van-cell">
            <van-col span="12" class="txt-left"
              >{{ $t("public.text1") }}:{{ userInfo.info.balance }}
            </van-col>
            <van-col span="12" class="txt-right"
              >{{ $t("public.text34") }}:{{ userInfo.info.hold_balance }}
            </van-col>
          </van-row>

          <van-row class="van-cell">
            <van-col span="8" class="txt-left"
              >{{ $t("public.text24") }}:
              <strong :class="cutNumber <= 10 ? 'red' : 'green'">
                {{ lotteryInfo ? lotteryIssue.cur.issue : "--------" }}</strong
              >
            </van-col>
            <van-col span="16" class="txt-right"
              >{{ $t("public.text41") }}:<strong class="red">{{
                config.yesterday_profit
              }}</strong>
            </van-col>
          </van-row>
          <van-row class="van-cell">
            <van-col span="14" class="txt-left">
              <div class="cuttime">
                <div class="cuttime-txt">
                  {{ $t("public.text33") }}:
                  {{ showTime(cutNumber) }}
                  <br />
                  <span
                    style="color: rgb(210, 169, 55)"
                    v-if="cutNumber <= 30"
                    >{{ $t("public.text35") }}</span
                  >
                </div>
              </div>
            </van-col>
            <van-col span="10" class="txt-right">
              {{ $t("public.text3") }}:
              <strong class="green">{{ userInfo.info.refund_today }}</strong>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="24">
              <div v-if="cutNumber > 0 && lotteryIssue">
                <div class="lottery-box">
                  {{ $t("room.di") }}
                  <b v-if="cutNumber > 0"> {{ lotteryIssue.last.issue }}</b
                  ><b v-else> ------ </b> {{ $t("room.qi") }}
                  <span v-for="item in lotteryIssue.last.code" :key="item">{{
                    item
                  }}</span>
                </div>
                <div class="lottery-box">
                  <div class="sum">
                    {{ sumCode }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="lottery-box">
                  {{ $t("room.di") }}
                  <b v-if="cutNumber > 0"> {{ lotteryIssue.last.issue }}</b
                  ><b v-else> ------ </b> {{ $t("room.qi") }}
                  <span v-for="item in ['?', '?', '?']" :key="item">{{
                    item
                  }}</span>
                </div>
                <div class="lottery-box">
                  <div class="sum">?</div>
                </div>
              </div>
            </van-col>
          </van-row>

          <van-row>
            <van-col span="24">
              <div class="lottery-box">
                <div class="sumlist">
                  <span class="kong" v-if="sumCode < 14">{{
                    $t("public.less")
                  }}</span>
                  <span class="duo" v-if="sumCode >= 14">{{
                    $t("public.more")
                  }}</span>
                  <span class="ping" v-if="sumCode % 2 == 0">{{
                    $t("public.double")
                  }}</span>
                  <span class="ping" v-if="sumCode % 2 != 0">{{
                    $t("public.single")
                  }}</span>
                  <span class="duo" v-if="sumCode >= 14 && sumCode % 2 == 0">{{
                    $t("public.more double")
                  }}</span>
                  <span class="kong" v-if="sumCode < 14 && sumCode % 2 == 0">{{
                    $t("public.less double")
                  }}</span>
                  <span class="duo" v-if="sumCode >= 14 && sumCode % 2 != 0">{{
                    $t("public.more single")
                  }}</span>
                  <span class="kong" v-if="sumCode < 14 && sumCode % 2 != 0">{{
                    $t("public.less single")
                  }}</span>
                </div>
              </div>
            </van-col>
          </van-row>
        </div>
      </div>

      <div class="lot-body">
        <div class="lottery-con">
          <van-row class="t-head con">
            <van-col span="6">{{ $t("room.text4") }}</van-col>
            <van-col span="5">{{ $t("room.text5") }}</van-col>
            <van-col span="4">{{ $t("room.text6") }}</van-col>
            <van-col span="5">{{ $t("room.text7") }}</van-col>
            <van-col span="4">{{ $t("room.text8") }}</van-col>
          </van-row>
          <div class="t-body">
            <div
              class="inner"
              :class="bet_record.length >= 10 ? 'start-move' : ''"
            >
              <van-row v-for="(item, key) in bet_record" :key="key">
                <van-col span="5">{{ item.username }}</van-col>
                <van-col span="7">{{ item.lottery_issue }}</van-col>
                <van-col span="4">{{ $t("public." + item.code) }}</van-col>
                <van-col span="4">{{ item.bet_amount }}</van-col>
                <van-col span="4">
                  <span class="bgcolor">{{ $t("room.text9") }}</span>
                </van-col>
              </van-row>
            </div>
          </div>
        </div>
        <!-- 投注按钮开始 -->
        <div class="lotr-box lottery-context txt-center rel">
          <div v-for="item in lotteryInfo.methods.pk" :key="item.id">
            <!-- <label>{{item.name}}</label> -->
            <div v-for="c_item in item.child" :key="c_item.id">
              <!-- <label>{{c_item.name}}</label> -->
              <div class="playlist">
                <span
                  class="button border-r5"
                  :class="{ active: active.includes(list_item.id) }"
                  v-for="list_item in c_item.child"
                  :key="list_item.id"
                  @click="payBtnHandle(list_item)"
                  >{{ list_item.name }}
                  {{ mathPrize(list_item, list_item.prize_level[0]) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fixed shadow">
        <van-field
          v-model="form.money"
          center
          clearable
          :placeholder="$t('public.input') + $t('room.text10')"
        >
          <template #button>
            <van-button
              size="small"
              type="warning"
              @click="submit"
              :disabled="cutNumber <= 30"
              >{{ $t("room.text9") }}</van-button
            >
            <van-button size="small" type="primary" @click="clear">{{
              $t("room.text11")
            }}</van-button>
          </template>
        </van-field>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  getCurrentInstance,
  reactive,
  ref,
  onBeforeUnmount,
  onMounted,
} from "vue";
import { useI18n } from "vue-i18n";
import { getCuntTime, getCaiType, lotteryBet } from "@/api/lottery";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import store from "@/store";
import io from "socket.io-client";
import Echo from "laravel-echo";
import { showTime } from "@/utils";
const { userInfo, config } = store.getters;
const router = useRouter();
const { t } = useI18n();
// const { io } = require("socket.io-client");
const { id, ident, type, room_id } = router.currentRoute.value.query;
// websocket
let host = window.location.hostname;
if (host == "localhost") {
  host += ":" + window.location.port;
}
const myEcho = new Echo({
  broadcaster: "socket.io",
  namespace: "Service.Events",
  client: io,
  host: host,
  transports: ["websocket", "polling"],
});
let bus;
onMounted(() => {
  // 请求api触发余额更新
  const ins = getCurrentInstance();
  bus = ins.appContext.config.globalProperties.$bus;
  myEcho.channel("bet.record." + ident).listen("BetRecord", (data) => {
    console.log(data);
    bet_record.value.push(data);
    if (bet_record.value.length > 20) {
      //超过20条数据 从前移除
      bet_record.value.shift();
    }
  });
});
onBeforeUnmount(() => {
  clearInterval(timers);
});
const form = reactive({
  money: "",
  address: "",
  security_password: "",
});
const loading = ref(true);
const cutNumber = ref(0);
const sumCode = ref(0);
const lotteryInfo = ref({});
const lotteryIssue = ref({});
const bet_record = ref([]);
const onlyCurrLottery = (callback) => {
  getCuntTime({ ident: ident })
    .then((res) => {
      lotteryIssue.value = res.data;
      cutNumber.value = res.data.cur.time_left;
      sumCode.value = res.data.last.code.reduce(
        (prev, cur) => Number(prev) + Number(cur)
      );
      callback && callback();
      console.log(lotteryInfo.value);
    })
    .finally(() => {
      Toast.clear();
      loading.value = false;
    });
};
// 获取基本信息，倒计时
const getLotteryData = (callback) => {
  // 测试数据
  // for (let i = 0; i < 10; i++) {
  //   bet_record.value.push({ lottery_issue: "20220530-255" });
  // }
  const toastLoading = Toast.loading({
    duration: 0,
    forbidClick: true,
  });
  getCaiType({ room_id: id, ident: ident })
    .then((res) => {
      lotteryInfo.value = res.data;
      onlyCurrLottery(callback);
      bus.emit("getMoney");
    })
    .catch(() => {
      toastLoading.clear();
    });
};
getLotteryData(() => {
  cutTime();
});

//倒计时 & 每间隔30秒请求核对数据
let checkTime = 0;
let timers;
const cutTime = () => {
  timers = setInterval(() => {
    if (cutNumber.value <= 0) {
      getLotteryData();
      setTimeout(function () {
        //更新余额
        bus.emit("getMoney");
      }, 5000);
    } else {
      cutNumber.value--;
      // 防止 倒计时结束和3秒轮训 同时请求
      if (checkTime >= 3 && cutNumber.value > 2) {
        checkTime = 0;
        onlyCurrLottery();
      } else {
        checkTime++;
      }
    }
  }, 1000);
  return;
};
// 计算奖金
const mathPrize = (item, prize_level) => {
  if (type == "Intermediate") {
    item.prize = (1 + Number(lotteryInfo.value.rebate_up)) * prize_level;
  } else {
    item.prize =
      (lotteryInfo.value.top_user_prize_level.pk +
        Number(lotteryInfo.value.rebate_up)) *
      prize_level;
  }

  return item.prize;
};
const active = ref([]);
// 选择投注
const payBtnHandle = (item) => {
  console.log(item.id);
  if (item.id == 102101003) {
    var findIdx = active.value.findIndex((f) => f === 102101004);
    if (findIdx !== -1) {
      return false;
    }
    console.log(findIdx);
  } else if (item.id == 102101004) {
    var findIdx = active.value.findIndex((f) => f === 102101003);
    if (findIdx !== -1) {
      return false;
    }
    console.log(findIdx);
  }
  var findIdx = active.value.findIndex((f) => f === item.id);

  if (findIdx !== -1) {
    active.value.splice(findIdx, 1);
  } else {
    active.value.push(item.id);
  }
  console.log(active.value);
};

// 提交投注
const submit = () => {
  if (!active.value.length) {
    Toast.fail(t("room.text12"));
    return;
  }
  if (form.money <= 0) {
    Toast.fail(t("room.text3"));
    return;
  }
  Toast.loading({
    duration: 0,
    forbidClick: true,
  });
  const projects = [];
  const childItem = lotteryInfo.value.methods.pk[0].child[0];
  active.value.forEach((id) => {
    const find = childItem.child.find((item) => item.id === id);
    if (find) {
      const obj = {
        method_ident: find.ident,
        method_name: childItem.name,
        code: find.name,
        num_count: 1,
        mode: 9,
        multiple: form.money,
        rebate: 0,
        total: form.money,
        position: [],
        prize: find.prize,
        room_id: room_id,
      };
      projects.push(obj);
    }
  });
  const sendData = {
    client_type: "WEB",
    play_mode: "pk",
    total: form.money * active.value.length,
    current_issue: lotteryIssue.value.cur.issue,
    projects: projects,
  };
  lotteryBet(sendData, lotteryInfo.value.lottery_ident)
    .then(() => {
      // 清空状态
      clear();
      //更新余额
      bus.emit("getMoney");
      Toast.success(t("public.success"));
    })
    .catch(() => {
      Toast.clear();
    });
};
const clear = () => {
  active.value = [];
  form.money = "";
};
</script>

<style lang="less" scoped>
.pay {
  overflow: hidden;
  height: calc(100vh - 46px);
  .lottery-box {
    max-width: none !important;

    .sumlist {
      span {
        width: auto;
        height: auto;
        padding: 0px 8px;
        color: #fff;
        font-size: 10px;
        background: #8b7df6;
        margin: 1vw 3vw;

        &.duo {
          background: #25ab0e;
        }

        &.kong {
          background: #e33a20;
        }

        &::after {
          display: none;
        }
      }
    }
  }
  .lot-body {
    height: calc(100vh - 300px);
    padding-bottom: 70px;
    overflow: scroll;
    background: #423f68;
  }
  .lottery-context {
    background: #413f68;
  }

  .playlist {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    transition: all 0.5s;

    span {
      background: #2a284f;
      padding: 5px;
      display: inline-block;
      width: 24%;
      height: 5vw;
      line-height: 5vw;
      padding: 5px;
      margin: 5px 10px;
      border: 1px solid #4d4d53;

      &.active {
        background: rgb(105, 63, 185);
        border: 1px solid #0e0d39;
      }
    }
  }

  .lottery-con {
    height: 75%;
    .t-head {
      background: #302e55;
      text-align: center;
    }

    .t-body {
      text-align: center;
      font-size: 12px;
      // border: 5px solid #302e55;
      height: 90%;
      background: #0e0d39;
      overflow: hidden;

      .van-row {
        border-bottom: 1px solid #413f68;

        .van-col {
          line-height: 25px;

          .bgcolor {
            padding: 2px 5px;
            background: #d10000;
          }
        }
      }
    }
  }

  .lotr-box {
    min-height: 100px;
  }

  h1 {
    margin: 0 0 10px 0;
    color: #b1e3f9;
    text-shadow: 1px 1px 1px #000;
  }

  .fixed {
    position: fixed;
    bottom: 0;
    width: 100%;

    .van-field__button button:first-child {
      margin-right: 5px;
    }
  }

  .money {
    margin-bottom: 2px;
  }

  .van-cell {
    padding: 5px 10px;
    font-size: 14px;
  }

  .lot-head {
    min-height: 150px;
  }

  .inner {
    &.start-move {
      animation: myMove 15s linear infinite;
      animation-fill-mode: forwards;
    }
  }
  ::v-deep .van-cell::after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    right: var(--van-padding-md);
    bottom: 0;
    left: var(--van-padding-md);
    border-bottom: none !important;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
  /*文字停顿滚动*/
  @keyframes myMove {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-280px);
    }
  }

  .van-cell-group--inset {
    margin: 10px 0;
  }
}
</style>
